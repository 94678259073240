import React from "react";
import styled from "styled-components";

function Header() {
  return (
    <HeaderWrapper>
      <Logo
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d5e6ea27c0a98b3c3de728ede00edfe7e49e734529a361fa502fce818bcedb35?placeholderIfAbsent=true&apiKey=839b9d2544f64aceafe2b4fcf938708e"
        alt="Company Logo"
      />
      <LogoText
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/cca20cf6675e3775902d75a590a880df8c25fbdc640a5d8bc098671aedaa6912?placeholderIfAbsent=true&apiKey=839b9d2544f64aceafe2b4fcf938708e"
        alt="Company Name"
      />
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  gap: 10px; /* Default gap for mobile */

  @media (min-width: 768px) {
    gap: 15px; /* Adjusted for tablets */
  }

  @media (min-width: 1280px) {
    gap: 20px; /* Adjusted for small monitors */
  }

  @media (min-width: 1440px) {
    gap: 8px; /* Adjusted for larger monitors */
  }
  @media (min-width: 1900px) {
    margin-top: 52px;
  }
`;

const Logo = styled.img`
  margin-left: 16px;
  width: 36px; /* Default size for mobile */
  aspect-ratio: 1;
  object-fit: contain;

  @media (min-width: 768px) {
    width: 28px; /* Adjusted for tablets */
  }

  @media (min-width: 1280px) {
    width: 33px; /* Adjusted for small monitors */
  }

  @media (min-width: 1440px) {
    width: 43px; /* Adjusted for larger monitors */
  }

  @media (min-width: 1900px) {
    width: 53px; /* Adjusted for larger monitors */
  }
`;

const LogoText = styled.img`
  width: 90px; /* Default size for mobile */
  aspect-ratio: 5.18;
  object-fit: contain;

  @media (min-width: 768px) {
    width: 140px; /* Adjusted for tablets */
  }

  @media (min-width: 1280px) {
    width: 161px; /* Adjusted for small monitors */
  }

  @media (min-width: 1400px) {
    width: 132px; /* Adjusted for larger monitors */
  }
`;

export default Header;
