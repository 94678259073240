import React from "react";
import styled from "styled-components";

const DotPattern = () => {
  const rows = 5;
  const dotsPerRow = 4;

  return (
    <PatternContainer>
      {[...Array(rows)].map((_, rowIndex) =>
        <DotRow key={rowIndex}>
          {[...Array(dotsPerRow)].map((_, dotIndex) => <Dot key={dotIndex} />)}
        </DotRow>
      )}
    </PatternContainer>
  );
};

const PatternContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 281px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
const DotRow = styled.div`
  display: flex;
  gap: 16px;
`;
const Dot = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 14px;
  height: 14px;
`;

export default DotPattern;
