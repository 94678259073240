import React from "react";
import styled from "styled-components";
import VerificationForm from "./VerificationForm";
import DotPattern from "./DotPattern";

const ConfirmationPage = () => {
  return (
    <StyledConfirmationPage>
      <MainContent>
        <LeftColumn>
          <ImageWrapper>
            <StyledImage
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/810f4ac5695fbad51ecc55c01776758e2cdb75e1cc845b133a682b103c256b06?placeholderIfAbsent=true&apiKey=839b9d2544f64aceafe2b4fcf938708e"
              alt="Background pattern"
            />
            <DotPattern />
          </ImageWrapper>
        </LeftColumn>
        <RightColumn>
          <VerificationForm />
        </RightColumn>
      </MainContent>
      <SideColumn>
        <DotPattern />
        <SideImage
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c126b91ed71797284ceffdc40f264a88ec83bd3231988807419ce8e56c946d23?placeholderIfAbsent=true&apiKey=839b9d2544f64aceafe2b4fcf938708e"
          alt="Side decoration"
        />
      </SideColumn>
    </StyledConfirmationPage>
  );
};

const StyledConfirmationPage = styled.main`
  background: var(--primary-blue-900, #052946);
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const MainContent = styled.div`
  display: flex;
  width: 69%;
  height: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
    width: 100%;
  }
`;

const LeftColumn = styled.section`
  width: 50%;
  display: flex;
  align-items: flex-start; /* Alinha o conteúdo ao topo */
  justify-content: flex-start; /* Alinha o conteúdo à esquerda */
  padding-top: 0; /* Remove qualquer padding extra */
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  max-width: 100%;
  height: auto;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-left: 0; /* Remove qualquer margem da esquerda */
  margin-top: 0; /* Garante que a imagem fique no topo */
`;

const RightColumn = styled.section`
  width: 50%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0;
  }
`;

const SideColumn = styled.aside`
  width: 31%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0;
    margin-top: 40px;
  }
`;

const SideImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

export default ConfirmationPage;
