import React from "react";
import styled from "styled-components";
const VerificationForm = () => {
  return (
    <FormContainer>
      <FormContent>
        <LogoWrapper>
          <LogoIcon
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/22b44cf59cb1dee083b3d1a33b88b088aeadaf09c2cf0f503f1f39494ea5e0b7?placeholderIfAbsent=true&apiKey=839b9d2544f64aceafe2b4fcf938708e"
            alt="Company logo icon"
          />

          <LogoText
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/5766f2e38c3c2ea916f18884a5b7011ac36d41ecd2eec5ef3eeb913f8c49f274?placeholderIfAbsent=true&apiKey=839b9d2544f64aceafe2b4fcf938708e"
            alt="Company logo text"
          />
        </LogoWrapper>

        <FormHeader>
          <FormTitle>Confirmar código</FormTitle>

          <FormDescription>
            Enviamos um código de confirmação para o email{" "}
            <strong>fabiano@taskfield.com</strong>
          </FormDescription>
        </FormHeader>

        <form>
          <InputGroup>
            <Label htmlFor="verificationCode">Código de verificação</Label>

            <InputWrapper>
              <Input
                type="text"
                id="verificationCode"
                placeholder="Insira o código de 6 dígitos"
                aria-label="Insira o código de 6 dígitos"
              />

              <ResendCode>Reenviar</ResendCode>
            </InputWrapper>
          </InputGroup>

          <SubmitButton type="submit">Confirmar</SubmitButton>
        </form>
      </FormContent>
    </FormContainer>
  );
};
const FormContainer = styled.div`
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  padding: 72px 48px;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 40px 20px;
  }
`;
const FormContent = styled.div`
  width: 100%;
  max-width: 354px;
  margin: 0 auto;
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
`;
const LogoIcon = styled.img`
  width: 36px;
  height: 36px;
`;
const LogoText = styled.img`width: 109px;`;
const FormHeader = styled.header`
  margin-top: 48px;
  text-align: center;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
const FormTitle = styled.h1`
  color: var(--neutral-black-900, #040405);
  font: 600 24px/1.2 Rubik, sans-serif;
`;
const FormDescription = styled.p`
  margin-top: 17px;
  color: var(--neutral-black-300, #737185);
  font: 400 16px/22px Inter, sans-serif;
`;
const InputGroup = styled.div`margin-top: 28px;`;
const Label = styled.label`
  display: block;
  color: var(--neutral-black-500, #4d4b5c);
  font: 600 14px/1.4 Inter, sans-serif;
  margin-bottom: 8px;
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid rgba(215, 215, 215, 1);
  padding: 16px;
`;

const Input = styled.input`
  border: none;
  outline: none;
  color: var(--neutral-black-200, #9d9aad);
  font: 400 14px/1.4 Inter, sans-serif;
  width: 100%;
`;

const ResendCode = styled.button`
  color: var(--primary-blue-500, #0c83ec);
  font: 700 14px/1.4 Inter, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  width: 100%;
  border-radius: 8px;
  background: var(--primary-blue-500, #0c83ec);
  color: #fff;
  font: 600 14px/1.4 Inter, sans-serif;
  padding: 12px;
  margin-top: 32px;
  border: none;
  cursor: pointer;
`;

export default VerificationForm;
