import React from "react";
import styled from "styled-components";

function InputField({ label, placeholder, type = "text" }) {
  return (
    <FieldWrapper>
      <Label htmlFor={label}>
        {label}
      </Label>
      <Input type={type} id={label} placeholder={placeholder} />
    </FieldWrapper>
  );
}

const FieldWrapper = styled.div`position: relative;`;

const Label = styled.label`
  color: #4d4b5c;
  font-weight: 300;
  font-size: 14px;
  display: block;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 365px;
  max-width: 365px;
  padding: 16px;
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  font-size: 14px;
  color: #263238;
  &::placeholder {
    color: #9d9aad;
  }
`;

export default InputField;
