import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import UserAvatars from "../../components/UserAvatars";
import Paragraph from "../../components/Paragraph";

import backgroundImage from "../../assets/1.png";
import avatar1 from "../../assets/av1.png";
import avatar2 from "../../assets/avatar2.jpg";
import avatar3 from "../../assets/avatar3.jpg";
import avatar4 from "../../assets/avatar4.jpg";

function SideContent() {
  const { t } = useTranslation();

  return (
    <SideWrapper>
      <BackgroundImage loading="lazy" />
      <ContentOverlay>
        <ContentContainer>
          <MainText>
            Manage any task, for any business, anywhere, anytime with TaskField.
          </MainText>
          <Description>
            A versátil plataforma do TaskField permite que você gerencie tarefas
            com todas as informações necessárias, sempre que precisar. Projetado
            para se adaptar às necessidades de qualquer negócio, o TaskField
            capacita você a se manter eficiente e no controle, independentemente
            do local ou do horário.
          </Description>
          <UserCount>
            <UserAvatars>
              <img src={avatar1} alt="Usuário 1" />
              <img src={avatar2} alt="Usuário 2" />
              <img src={avatar3} alt="Usuário 3" />
              <img src={avatar4} alt="Usuário 4" />
              <Paragraph style={{ marginLeft: "24px" }}>
                {t("Over 2,000 companies have joined us, now it's your turn.")}
              </Paragraph>
            </UserAvatars>
          </UserCount>
        </ContentContainer>
      </ContentOverlay>
    </SideWrapper>
  );
}

const SideWrapper = styled.aside`
  position: relative;
  width: 100%;
  min-height: 1024px;
  overflow: hidden;
  @media (max-width: 991px) {
    min-height: 600px; /* Ajuste para tablets */
  }
  @media (max-width: 768px) {
    min-height: 400px; /* Ajuste para dispositivos móveis */
  }
`;

const BackgroundImage = styled.div`
  flex-grow: 1;
  height: 100vh;
  background: url(${backgroundImage}) no-repeat center center;
  background-size: cover;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(52, 102, 150, 0.5),
      rgba(10, 17, 37, 0.6)
    );
    z-index: -1;
  }

  @media (max-width: 768px) {
    display: none; /* Esconder imagem de fundo em dispositivos móveis */
  }
`;

const ContentOverlay = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 36px 20px;
  color: #fff;
  z-index: 2;
  height: 100%;

  @media (max-width: 768px) {
    padding: 24px 16px; /* Ajustar padding para dispositivos móveis */
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 120px;
  margin-left: 2%;
  width: 100%;

  @media (max-width: 991px) {
    margin-bottom: 80px; /* Ajustar margem inferior para tablets */
  }

  @media (max-width: 768px) {
    align-items: center; /* Centralizar conteúdo em dispositivos móveis */
    margin-bottom: 40px; /* Ajustar margem inferior para dispositivos móveis */
    text-align: center; /* Centralizar texto */
  }
`;

const MainText = styled.h2`
  font-family: 'Rubik', sans-serif;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 8px;
  width: 70%;
  position: relative;
  z-index: 2;

  @media (max-width: 991px) {
    font-size: 36px; /* Reduzir tamanho do texto para tablets */
    width: 80%;
  }

  @media (max-width: 768px) {
    font-size: 28px; /* Reduzir tamanho do texto para dispositivos móveis */
    width: 90%;
  }
`;

const Description = styled.p`
  margin-top: 4px;
  font-size: 16px;
  line-height: 22px;
  width: 70%;
  color: rgba(255, 255, 255, 0.8);

  @media (max-width: 991px) {
    font-size: 14px; /* Reduzir tamanho da fonte para tablets */
    line-height: 20px;
    width: 80%;
  }

  @media (max-width: 768px) {
    font-size: 12px; /* Reduzir tamanho da fonte para dispositivos móveis */
    line-height: 18px;
    width: 90%;
  }
`;

const UserCount = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 32px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);

  @media (max-width: 768px) {
    flex-direction: column; /* Organizar em coluna para dispositivos móveis */
    gap: 8px; /* Ajustar espaçamento */
    font-size: 14px; /* Reduzir tamanho da fonte */
  }
`;

export default SideContent;
