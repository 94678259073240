// src/index.js
import React from "react";
import ReactDOM from "react-dom/client"; // Atualizado para 'react-dom/client'
import App from "./App";
import "./i18n"; // Importa o i18n
import { Suspense } from "react"; // Importar Suspense para lidar com carregamento assíncrono
import GlobalStyle from "./GlobalStyles";

const root = ReactDOM.createRoot(document.getElementById("root")); // Usando createRoot
root.render(
  <React.StrictMode>
    <Suspense fallback={<div />}>
      <GlobalStyle />
      <App />
    </Suspense>
  </React.StrictMode>
);
