import React from "react";
import "./ComingSoon.css";

import logo from "../../assets/images/taskfield-logo.png";
import rocket from "../../assets/rocket.png";

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div className="mt-5 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-11">
              <div className="text-center">
                <h2>
                  <a href="/">
                    <img src={logo} alt="main-logo" height="46" />
                  </a>
                </h2>

                <img
                  className="rocket"
                  src={rocket}
                  alt="rocket"
                  height="240"
                />

                <h3 className="mt-4 text-white">
                  "Fique ligado, lançaremos em breve!
                </h3>
                <p className="text-white-50">
                  Estamos construindo um sistema incrível para simplificar a
                  gestão das suas tarefas de campo.
                </p>

                <h3 className="mt-4 text-white">
                  Stay tuned, we're launching very soon!
                </h3>
                <p className="text-white-50">
                  We're building an exceptional system to simplify your field
                  task management.
                </p>

                <div className="row mt-5 justify-content-center">
                  <div className="col-md-8">
                    <div
                      data-countdown="2023/02/13"
                      className="counter-number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
