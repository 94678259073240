// src/pages/Home.js
import React from "react";

function Home() {
  return (
    <div>
      <h1>Página Inicial</h1>
      {/* Conteúdo da sua página inicial */}
    </div>
  );
}

export default Home;
