import React from "react";
import styled from "styled-components";

function InputField({ label, placeholder, type = "text" }) {
  return (
    <FieldWrapper>
      <Label htmlFor={label}>
        {label}
      </Label>
      <Input type={type} id={label} placeholder={placeholder} />
    </FieldWrapper>
  );
}

const FieldWrapper = styled.div`position: relative;`;

const Label = styled.label`
  color: #4d4b5c;
  font-weight: 500;
  font-size: 12px;
  display: block;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%; /* Default for mobile */
  max-width: 290px; /* Limit width on larger screens */
  padding: 12px; /* Adjust padding for mobile */
  border: 1px solid #d7d7d7;

  border-radius: 8px;
  font-size: 14px;
  color: #263238;

  &:focus {
    outline: none;
    border: 1px solid #0c83ec;
  }
  &::placeholder {
    color: #9d9aad;
  }

  @media (min-width: 768px) {
    width: 80%; /* Adjust for tablets */
    padding: 14px; /* Slightly increased padding */
  }

  @media (min-width: 1280px) {
    width: 365px; /* Fixed width for small monitors */
    padding: 12px; /* Original padding */
  }
`;

export default InputField;
