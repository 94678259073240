// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import SignUp from "./pages/signup/SignUp";
import SignIn from "./pages/signin/SignIn";
import RecoverPassword from "./pages/recover/RecoveryPass";
import ConfirmationPage from "./pages/confirmation/ConfirmationPage";
import ComingSoon from "./pages/coming-soon";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ComingSoon />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/home" element={<Home />} />
        <Route path="/RecoverPassword" element={<RecoverPassword />} />
        <Route path="/Confirmation" element={<ConfirmationPage />} />

        {/* Outras rotas */}
      </Routes>
    </Router>
  );
}
export default App;
