import React from "react";
import styled from "styled-components";

function Header() {
  return (
    <HeaderWrapper>
      <Logo
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d5e6ea27c0a98b3c3de728ede00edfe7e49e734529a361fa502fce818bcedb35?placeholderIfAbsent=true&apiKey=839b9d2544f64aceafe2b4fcf938708e"
        alt="Company Logo"
      />
      <LogoText
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/cca20cf6675e3775902d75a590a880df8c25fbdc640a5d8bc098671aedaa6912?placeholderIfAbsent=true&apiKey=839b9d2544f64aceafe2b4fcf938708e"
        alt="Company Name"
      />
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Logo = styled.img`
  width: 53px;
  aspect-ratio: 1;
  object-fit: contain;
`;

const LogoText = styled.img`
  width: 161px;
  aspect-ratio: 5.18;
  object-fit: contain;
`;

export default Header;
