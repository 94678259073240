import React from "react";
import styled from "styled-components";
import InputField from "./InputField";
import { Link } from "react-router-dom"; // Importa o Link do react-router-dom

function RecoveryPassForm() {
  return (
    <FormWrapper>
      <FormHeader>
        <Title>Forgot your password?</Title>
        <Subtitle>
          Enter your email and we'll send you a link to reset it.
        </Subtitle>
      </FormHeader>
      <Form>
        <InputField
          label="Email"
          placeholder="seunome@email.com"
          type="email"
        />
        <TermsCheckbox>
          <input type="checkbox" id="terms" />
          <label htmlFor="terms">
            Li e aceito os <TermsLink to="/terms">Termos de uso</TermsLink>
          </label>
        </TermsCheckbox>
        <SubmitButton type="submit">Reset password</SubmitButton>
      </Form>
      <LoginPrompt>
        Back to <LoginLink to="/">login page</LoginLink>
      </LoginPrompt>
    </FormWrapper>
  );
}

const FormWrapper = styled.section`
  margin-top: 36px;
  font-family: Inter, sans-serif;
  max-width: 400px; /* Define uma largura máxima */
  background-color: #fff;
`;

const FormHeader = styled.header`margin-bottom: 28px;`;

const Title = styled.h1`
  color: #040405;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  font-family: Rubik, sans-serif;
`;

const Subtitle = styled.p`
  color: #737185;
  font-size: 16px;
  line-height: 1.4;
  margin-top: 17px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TermsCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  color: #4d4b5c;
  font-weight: 600;
`;

const TermsLink = styled(Link)`
  color: #0c83ec;
  font-weight: 700;
  text-decoration: none;
`;

const SubmitButton = styled.button`
  background-color: #0c83ec;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

const LoginPrompt = styled.p`
  text-align: center;
  margin-top: 32px;
  font-size: 16px;
`;

const LoginLink = styled(Link)`
  color: #0c83ec;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
`;

export default RecoveryPassForm;
