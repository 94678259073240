import React from "react";
import styled from "styled-components";

function PhoneInput() {
  return (
    <PhoneInputWrapper>
      <Label htmlFor="phone">Celular</Label>
      <InputGroup>
        <CountryCode>
          🇧🇷 +55
          <DropdownIcon
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1373f5a9b22154b8626d73dc95f6d87c9329cd4ef271b8b4fe527eea284c3c06?placeholderIfAbsent=true&apiKey=839b9d2544f64aceafe2b4fcf938708e"
            alt="Dropdown"
          />
        </CountryCode>
        <PhoneNumber id="phone" placeholder="(00) 00000-0000" />
      </InputGroup>
    </PhoneInputWrapper>
  );
}

const PhoneInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  color: #4d4b5c;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 8px;
`;

const InputGroup = styled.div`
  display: flex;
  width: 100%; /* Ajuste para largura total por padrão */

  @media (min-width: 768px) {
    max-width: 350px; /* Ajuste para tablets */
  }

  @media (min-width: 1280px) {
    max-width: 399px; /* Tamanho padrão para monitores */
  }
`;

const CountryCode = styled.div`
  display: flex;
  align-items: center;
  padding: 8px; /* Ajuste para dispositivos móveis */
  background-color: #f6f6fa;
  border: 1px solid #d7d7d7;
  border-right: none;
  border-radius: 8px 0 0 8px;
  color: #737185;
  font-size: 14px;

  @media (min-width: 768px) {
    padding: 12px; /* Ajuste para tablets */
  }

  @media (min-width: 1280px) {
    padding: 8px; /* Ajuste para monitores */
  }
`;

const DropdownIcon = styled.img`
  width: 18px; /* Ajuste para dispositivos móveis */
  height: 18px;
  margin-left: 16px; /* Menor margem em dispositivos móveis */

  @media (min-width: 768px) {
    width: 20px; /* Tamanho padrão para tablets e monitores */
    height: 20px;
    margin-left: 16px;
  }
`;

const PhoneNumber = styled.input`
  flex: 1;
  padding: 8px; /* Ajuste para dispositivos móveis */
  border: 1px solid #d7d7d7;
  border-radius: 0 8px 8px 0;
  font-size: 14px;
  color: #263238;

  &::placeholder {
    color: #9d9aad;
  }

  @media (min-width: 768px) {
    padding: 14px; /* Ajuste para tablets */
  }

  @media (min-width: 1280px) {
    padding: 14px; /* Ajuste para monitores */
  }
`;

export default PhoneInput;
