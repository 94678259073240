import React from "react";
import styled from "styled-components";

function Footer() {
  return (
    <FooterWrapper>
      © All rights reserved, taskfield is a registered trademark.
    </FooterWrapper>
  );
}

const FooterWrapper = styled.footer`
  position: absolute;
  bottom: 24px;
  left: 48px;
  right: 48px;
  text-align: left;
  font-size: 12px;
  color: #6c757d;
  width: 380px;
`;

export default Footer;
