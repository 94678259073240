// src/components/Paragraph.js
import styled from "styled-components";

const Paragraph = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 24px;
  margin: 0;
  position: relative;
  z-index: 2;
`;

export default Paragraph;
