import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import UserAvatars from "../../components/UserAvatars";
import Paragraph from "../../components/Paragraph";

import backgroundImage from "../../assets/7.jpg";
import avatar9 from "../../assets/avatar9.jpg";
import avatar10 from "../../assets/avatar10.jpg";
import avatar11 from "../../assets/avatar11.jpg";
import avatar12 from "../../assets/avatar12.png";

function SideContent() {
  const { t } = useTranslation();

  return (
    <SideWrapper>
      <BackgroundImage loading="lazy" />
      <ContentOverlay>
        <ContentContainer>
          <MainText>
            Manage any task, for any business, anywhere, anytime with TaskField.
          </MainText>
          <Description>
            A versátil plataforma do TaskField permite que você gerencie tarefas
            com todas as informações necessárias, sempre que precisar. Projetado
            para se adaptar às necessidades de qualquer negócio, o TaskField
            capacita você a se manter eficiente e no controle, independentemente
            do local ou do horário.
          </Description>
          <UserCount>
            <UserAvatars>
              <img src={avatar9} alt="Usuário 9" />
              <img src={avatar10} alt="Usuário 10" />
              <img src={avatar11} alt="Usuário 11" />
              <img src={avatar12} alt="Usuário 12" />
              <Paragraph style={{ marginLeft: "24px" }}>
                {t("Over 2,000 companies have joined us, now it's your turn.")}
              </Paragraph>
            </UserAvatars>
          </UserCount>
        </ContentContainer>
      </ContentOverlay>
    </SideWrapper>
  );
}

const SideWrapper = styled.aside`
  position: relative;
  width: 100%;
  min-height: 1024px;
  overflow: hidden;
  @media (max-width: 991px) {
    width: 100%;
    min-height: auto;
  }
`;

const BackgroundImage = styled.div`
  flex-grow: 1;
  height: 100vh;
  background: url(${backgroundImage}) no-repeat center center;
  background-size: cover;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(52, 102, 150, 0.5),
      rgba(10, 17, 37, 0.6)
    );
    z-index: -1;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ContentOverlay = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Alinha o conteúdo na base */
  padding: 36px 20px;
  color: #fff;
  z-index: 2;
  height: 100%; /* Ocupa a altura total para alinhar ao final */
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Alinha os componentes na base */
  align-items: flex-start;
  margin-bottom: 120px;
  margin-left: 2%;
  width: 100%;
`;

const MainText = styled.h2`
  font-family: 'Rubik', sans-serif;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 8px;
  width: 70%;
  position: relative;
  z-index: 2;
`;

const Description = styled.p`
  margin-top: 4px;
  font-size: 16px;
  line-height: 22px;
  width: 70%;
  color: rgba(255, 255, 255, 0.8);
`;

const UserCount = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 32px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
`;

export default SideContent;
