import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Header from "./Header";
import RecoveryPassForm from "./RecoveryPassForm";
import Footer from "./Footer";
import SideContent from "./SideContent";

function RecoveryPassPage() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Verifica se o documento está completamente carregado
    const handleLoad = () => {
      setIsLoaded(true);
    };

    // Adiciona o evento de carregamento
    if (document.readyState === "complete") {
      // Caso a página já esteja carregada, define como carregada
      setIsLoaded(true);
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  if (!isLoaded) {
    return (
      <LoaderWrapper>
        <Loader />
        <LoaderText>Carregando...</LoaderText>
      </LoaderWrapper>
    );
  }

  return (
    <PageWrapper>
      <MainContent>
        <Header />
        <RecoveryPassForm />
        <Footer />
      </MainContent>
      <SideContent />
    </PageWrapper>
  );
}

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
`;

const Loader = styled.div`
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoaderText = styled.p`
  font-size: 16px;
  margin-top: 16px;
  color: #333;
`;

const PageWrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  width: 400px;
  flex-shrink: 0;
  background-color: #fff;
  padding: 80px 32px 0px 32px;
`;

export default RecoveryPassPage;
