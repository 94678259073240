// src/components/UserAvatars.js
import styled from "styled-components";

const UserAvatars = styled.div`
  display: flex;
  margin-top: 24px;
  position: relative;
  z-index: 2;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: -10px;
    border: 2px solid white;
  }

  p {
    margin-left: 24px;
  }
`;

export default UserAvatars;
